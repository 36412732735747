<template>
  <div>
    <div class="d-flex justify-content-around">
      <v-date-picker
        v-model="startDay"
        :input-debounce="300"
        :min-date="firstDate"
        :max-date="lastDate"
        :disabled-dates="missingDays"
        :popover="{ visibility: 'focus', placement: 'bottom' }"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            class="bg-white border px-2 py-1 rounded d-block mx-auto text-center"
            placeholder="Startdatum wählen"
            :value="inputValue"
            v-on="inputEvents"
          />
        </template>
      </v-date-picker>
      <v-date-picker
        v-model="endDay"
        :input-debounce="300"
        :min-date="firstDate"
        :max-date="lastDate"
        :disabled-dates="missingDays"
        :popover="{ visibility: 'focus', placement: 'bottom' }"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            class="bg-white border px-2 py-1 rounded d-block mx-auto text-center"
            placeholder="Enddatum wählen"
            :value="inputValue"
            v-on="inputEvents"
          />
        </template>
      </v-date-picker>
    </div>
    <vue-loading-button
      class="d-block btn btn-outline-primary mx-auto mt-2"
      @click.native="generateVideo(startDay, endDay)"
      :loading="isLoading"
      >Video generieren</vue-loading-button
    >
    <hr />
    <div class="videoView">
      <video :key="videosrc" controls>
        <source :src="videosrc" type="video/mp4" />
      </video>
      <a class="d-block btn btn-primary mx-auto" :href="videosrc" download="video_range">Video herunterladen</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { en, de } from "vuejs-datepicker/dist/locale";
import DayView from "./DayView.vue";
import moment from "moment";

export default {
  name: "Hero",
  components: {
    DayView,
  },
  data() {
    return {
      firstDate: null,
      lastDate: null,
      videosrc: `https://baucam.protech.bz/api/videouser?${new Date()}#t=0.001`,
      startDay: null,
      endDay: null,
      selectedDay: null,
      missingDays: null,
      en: en,
      de: de,
      jwt: null,
      images: null,
      cookie: null,
      status: 0,
      isLoading: false,
    };
  },
  created() {
    this.getFirstLastDate();
    this.getMissingDays();
  },
  watch: {
    status: function () {
      if (this.status == "success") {
        this.videosrc = `https://baucam.protech.bz/api/videouser?${new Date()}#t=0.001`;
      }
      this.status = null;
    },
  },
  methods: {
    getPictures(date, accessToken) {
      axios
        .get(`https://baucam.protech.bz/baucam/api/${date}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            withCredentials: true,
          },
        })
        .then(async (response) => {
          this.images = response.data.thumbnail;
          if (response.data.video[0]) {
            this.download = response.data.video[0];
            this.video = response.data.video[0];
          } else {
            this.video = 0;
          }
          this.images = response.data.swipeOrder;
        });
    },
    getFirstLastDate() {
      axios
        .get(`https://baucam.protech.bz/api/lastfirstdate`, {
          withCredentials: true,
        })
        .then((response) => {
          this.firstDate = new Date(response.data.firstdate);
          this.startDay = new Date(response.data.firstdate);
          this.endDay = new Date(response.data.lastdate);
          this.lastDate = new Date(response.data.lastdate);
        });
    },
    getMissingDays() {
      axios
        .get(`https://baucam.protech.bz/api/checkdates`, {
          withCredentials: true,
        })
        .then((response) => {
          this.missingDays = response.data;
        });
    },
    generateVideo(startDate, endDate) {
      this.isLoading = true;
      let start = moment(startDate).format("YYYY-MM-DD");
      let end = moment(endDate).format("YYYY-MM-DD");
      axios
        .get(`https://baucam.protech.bz/api/generatevideo/${start}/${end}`, {
          withCredentials: true,
        })
        .then((response) => {
          this.status = response.data.status;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.is-disabled {
  cursor: default !important;
}
@media only screen and (max-width: 430px) {
  input {
    width: 130px;
  }
}
</style>

<style lang="scss" scoped>
video {
  width: 100%;
  height: auto;
}
.videoView {
  margin-bottom: 3em;
}
</style>
