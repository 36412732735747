<template>
  <div class="nav-container mb-3">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
      <div class="container">
        <a class="header-link" id="bigLogo" @click.prevent="homeRoute">
          <img src="https://www.protech.bz/img/layout/logo.png" alt="logo" class="header-logo"
        /></a>
        <ul id="mobileAuthNavBar" class="navbar-nav d-md-none d-flex text-right" v-if="$auth.isAuthenticated">
          <li>
            <a class="header-link" @click.prevent="homeRoute">
              <img src="https://www.protech.bz/img/layout/logo.png" alt="logo" class="header-logo-mobile"
            /></a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="profileDropDown" data-toggle="dropdown">
              <img
                :src="$auth.user.picture"
                alt="User's profile picture"
                class="nav-user-profile rounded-circle"
                width="40"
              />
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <div class="dropdown-header">{{ $auth.user.name }}</div>
              <!-- <router-link
                  to="/profile"
                  class="dropdown-item dropdown-profile"
                >
                  <font-awesome-icon class="mr-3" icon="user" />Profile
                </router-link> -->
              <a id="qsLogoutBtn" href="#" class="dropdown-item" @click.prevent="logout">
                <font-awesome-icon class="mr-3" icon="power-off" />Log out
              </a>
            </div>
          </li>
          <div class="d-none">
            <li class="nav-item">
              <span class="user-info">
                <h6 class="d-inline-block">{{ $auth.user.name }}</h6>
              </span>
            </li>
            <li>
              <font-awesome-icon icon="power-off" class="mr-3" />
              <a id="qsLogoutBtn" href="#" class @click.prevent="logout">Log out</a>
            </li>
          </div>
        </ul>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="showCollapse"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav mr-auto text-right">
            <li class="nav-item">
              <router-link to="/" class="nav-link"><span class="nav-span">Home</span></router-link>
            </li>
            <li class="nav-item">
              <router-link to="/video" class="nav-link"><span class="nav-span">Video generieren</span> </router-link>
            </li>
          </ul>
          <ul class="navbar-nav d-none d-md-block">
            <li v-if="!$auth.isAuthenticated && !$auth.loading" class="nav-item">
              <button id="qsLoginBtn" class="btn btn-primary btn-margin" @click.prevent="login">Login</button>
            </li>

            <li class="nav-item dropdown" v-if="$auth.isAuthenticated">
              <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
                <img
                  :src="$auth.user.picture"
                  alt="User's profile picture"
                  class="nav-user-profile rounded-circle"
                  width="50"
                />
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-header">{{ $auth.user.name }}</div>
                <!-- <router-link
                  to="/profile"
                  class="dropdown-item dropdown-profile"
                >
                  <font-awesome-icon class="mr-3" icon="user" />Profile
                </router-link> -->
                <a id="qsLogoutBtn" href="#" class="dropdown-item" @click.prevent="logout">
                  <font-awesome-icon class="mr-3" icon="power-off" />Log out
                </a>
              </div>
            </li>
          </ul>

          <ul class="navbar-nav d-md-none" v-if="!$auth.isAuthenticated && !$auth.loading">
            <button id="qsLoginBtn" class="btn btn-primary btn-block" @click="login">Log in</button>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      displayMobileProfile: null,
    };
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$cookies.remove("token");
      this.$auth.logout();
      this.$router.push({ path: "/" });
    },
    homeRoute() {
      this.$router.push({ path: "/" });
    },
    // toggleProfile() {
    //   this.displayMobileProfile = !this.displayMobileProfile;
    // },
  },
  watch: {
    $route() {
      window.$(".collapse").collapse("hide");
    },
  },
};
</script>

<style lang="scss">
#mobileAuthNavBar {
  // justify-content: space-between;
  a {
    color: black !important;
    text-decoration: none;
  }
}
#profileDropDown:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
li {
  display: block;
}
.header-link {
  cursor: pointer;
}
.header-logo {
  width: 200px;
  margin-right: 30px;
}

.container{
  align-items: center;;
}

@media only screen and (min-width: 768px) {
  .router-link-exact-active {
    border-bottom: 2px solid #2c90cf;
  }
  .navbar-nav {
    .nav-item {
      padding: 5px 15px;
    }
  }
}

@media only screen and (max-width: 767px) {
  #bigLogo{
    display: none;
  }
  .header-logo{
    display: none;
  }
  #mobileAuthNavBar{
    flex-direction: row;
  }
  .dropdown-menu {
    position: absolute !important;
  }
  .header-logo-mobile {
    width: 200px;
    margin-right: 25px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .router-link-exact-active .nav-span {
    padding-bottom: 2px;
    border-bottom: 2px solid #2c90cf;
  }
  .router-link-exact-active {
    border-bottom: none !important;
  }
  .navbar-nav {
    .nav-item {
      padding: 2px 0px;
      text-align: left;
    }
  }
  .container{
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: 374px) {
  .header-logo-mobile{
    width: 150px;
    margin-right: 15px;
  }
}

.navbar-nav {
  font-weight: 600;
}

.nav-user-profile {
  border-radius: 50% 50%;
  max-width: 3.785rem;
  height: auto;
  // box-shadow: 0 0 4px 0 #b3bac7;
}

.nav-item.dropdown .dropdown-menu {
  box-shadow: 0 0 2px 0 #b3bac7;
}

.nav-item.dropdown .dropdown-header {
  border-bottom: 1px solid #b3bac7;
  font-size: 1rem;
  font-weight: 600;
  color: #041433;
  text-align: center;
}

.dropdown-profile {
  border-bottom: 1px solid #b3bac7;
  font-size: 1rem;
  font-weight: 600;
}
</style>
