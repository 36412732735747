<template>
  <div id="app" class="d-flex flex-column h-100">
    <nav-bar />
    <div class="container flex-grow-1">
      <error />
      <div class="mt-2">
        <keep-alive> <router-view /></keep-alive>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import Error from "./components/Error";
import MyFooter from "./components/Footer";

export default {
  components: {
    NavBar,
    Error,
    MyFooter,
  },
};
</script>
