<template>
  <div>
    <video-view />
  </div>
</template>

<script>
import VideoView from "../components/VideoView";

export default {
  name: "videoview",
  components: {
    VideoView,
  }
};
</script>