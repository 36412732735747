<template>
  <div>
    <vue-picture-swipe :images="images"> </vue-picture-swipe>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default() {
        return ["no images"];
      },
    },
  },
};
</script>