<template>
  <div>
    <v-date-picker
      v-model="selectedDay"
      :input-debounce="300"
      @dayclick="dayClicked"
      :min-date="firstDate"
      :max-date="lastDate"
      :disabled-dates="missingDays"
      :popover="{ visibility: 'focus', placement: 'bottom' }"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <input
          class="bg-white border px-2 py-1 rounded d-block mx-auto text-center"
          placeholder="Datum wählen"
          :value="inputValue"
          v-on="inputEvents"
        />
      </template>
    </v-date-picker>
    <hr />
    <div v-if="video">
      <video v-for="vid in video" :key="vid" controls>
        <source :src="vid" type="video/mp4" :download="download" />
      </video>
      <a class="btn btn-primary mx-auto btn-video d-block" :href="video" :download="selectedDay">Video herunterladen</a>
    </div>
    <div v-if="selectedDay && video == 0" class="alert alert-warning" role="alert">Video wird am Abend generiert</div>
    <day-view :images="images"> </day-view>
  </div>
</template>

<script>
import axios from "axios";
import { en, de } from "vuejs-datepicker/dist/locale";
import DayView from "./DayView.vue";
import { eventBus } from "../event.js";

export default {
  name: "Hero",
  components: {
    DayView,
  },
  data() {
    return {
      video: null,
      firstDate: null,
      lastDate: null,
      selectedDay: null,
      missingDays: null,
      en: en,
      de: de,
      images: null,
    };
  },
  mounted() {
    eventBus.$on("cookieSet", () => {
      this.getFirstLastDate();
      this.getMissingDays();
    });
    // this.getFirstLastDate();
    // this.getMissingDays();
    setTimeout(() => {
      this.getFirstLastDate();
      this.getMissingDays();
    }, 500);
  },
  watch: {
    lastDate: function () {
      this.selectedDay = this.lastDate;
      this.getPictures(this.selectedDay);
    },
  },
  methods: {
    async dayClicked(day) {
      if (!day.isDisabled) {
        const accessToken = await this.$auth.getTokenSilently();
        this.jwt = accessToken;
        this.selectedDay = day.id;
        this.getPictures(day.id, this.jwt);
      }
    },
    getPictures(date) {
      axios
        .get(`https://baucam.protech.bz/baucam/api/${date}`, {
          headers: {
            // Authorization: `Bearer ${accessToken}`,
            withCredentials: true,
          },
        })
        .then(async (response) => {
          this.images = response.data.thumbnail;
          if (response.data.video[0]) {
            this.download = response.data.video[0];
            this.video = response.data.video[0];
          } else {
            this.video = 0;
          }
          this.images = response.data.swipeOrder;
        });
    },
    getFirstLastDate() {
      axios
        .get(`https://baucam.protech.bz/api/lastfirstdate`, {
          withCredentials: true,
        })
        .then((response) => {
          this.firstDate = response.data.firstdate;
          this.lastDate = response.data.lastdate;
        });
    },
    getMissingDays() {
      axios
        .get(`https://baucam.protech.bz/api/checkdates`, {
          withCredentials: true,
        })
        .then((response) => {
          this.missingDays = response.data;
        });
    },
  },
};
</script>

<style lang="scss">
.btn-video {
  width: 175px;
}

video {
  width: 85%;
  height: 533px;
  margin: 0 auto;
  display: block;
  margin-bottom: 1em;
}

@media only screen and (max-width: 1200px) {
  video {
    height: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 650px) {
  video {
    margin-bottom: 1em;
  }
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #2c90cf !important;
  border-color: #2c90cf !important;
}

.btn-outline-primary {
  color: #2c90cf !important;
  border-color: #2c90cf !important;
}

.btn-outline-primary:hover {
  color: white !important;
}
</style>
