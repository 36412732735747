import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { Auth0Plugin, getInstance } from "./auth";
import hljs from "highlight.js/lib/core";
import "highlight.js/styles/github.css";
import VueCookies from 'vue-cookies'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { domain, clientId, audience } from "../auth_config.json";
import VCalendar from 'v-calendar';
import { eventBus } from './event.js';

Vue.config.productionTip = false;

Vue.use(VueCookies)

Vue.use(hljs.vuePlugin);

Vue.use(VCalendar, { locale: 'de' });

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});



library.add(faLink, faUser, faPowerOff);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import DatePicker from 'v-calendar/lib/components/date-picker.umd'
Vue.component('date-picker', DatePicker)

import VuePictureSwipe from '../vue-picture-swipe/src/main.js';
Vue.component('vue-picture-swipe', VuePictureSwipe);

import VueLoadingButton from './components/VueLoadingButton'
Vue.component('vue-loading-button', VueLoadingButton);

// ---- to get token on Auth0Plugin instance load
new Vue({
  router,
  data() {
    const instance = getInstance();
    instance.$watch("loading", async loading => {
      if (!loading && instance.isAuthenticated) {
        const token = await instance.getTokenSilently();
        this.$cookies.set("token", token, 0);
        eventBus.$emit('cookieSet', true);
      }
    });
    return { jwtToken: null, }
  },
  render: h => h(App)
}).$mount("#app");
